import React from "react";
import citron from "../../public/date-site/citron.png";
import citron2 from "../../public/date-site/citron_2.png";
import home from "../../public/date-site/home.png";
import "../../App.css";
import { ParagraphText } from "../../components/paragraph-text";
import Button from "@mui/material/Button";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import "./date-site.css";

export const DateSite = () => {
  const navigate = useNavigate();
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <img src={home} alt="img" width="85%" />
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            paddingTop: "20px",
          }}
        >
          <img
            className="side-img"
            style={{
              paddingTop: "30px",
              height: "fit-content",
              paddingRight: "5%",
            }}
            src={citron}
            alt="img"
            width="15%"
          />
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ParagraphText
              lines={[
                "ainsi que leurs parents,",
                "ont la joie de vous convier à leur mariage,",
              ]}
              style={{
                fontSize: "clamp(8pt, 1.5vw, 13pt)",
                lineHeight: "4px",
                paddingTop: "10px",
              }}
            />
            <ParagraphText
              lines={["les 7 et 8 septembre 2024", "au Domaine de Franco"]}
              style={{
                fontFamily: "siteFont",
                fontSize: "clamp(30pt, 6vw, 55pt)",
                lineHeight: "5px",
                paddingTop: "40px",
              }}
            />
            <ParagraphText
              lines={["La cérémonie religieuse aura lieu à Saint-Tropez."]}
              style={{
                fontWeight: "bold",
                paddingTop: "40px",
                fontSize: "clamp(8px, 1.5vw, 15px)",
              }}
            />
            <ParagraphText
              lines={[
                "Nous espérons pouvoir profiter de ce week-end en votre présence.",
                "Une réponse est souhaitée avant le 7 juin 2024.",
              ]}
              style={{
                paddingTop: "40px",
                lineHeight: "4px",
                fontSize: "clamp(8px, 1.5vw, 15px)",
              }}
            />
            <div style={{ paddingBlock: "20px" }}>
              <Button
                size="small"
                variant="outlined"
                endIcon={<SendIcon />}
                color="inherit"
                onClick={() => navigate("/rsvp")}
              >
                Répondre
              </Button>
            </div>
          </span>
          <img
            className="side-img"
            style={{
              height: "fit-content",
              paddingTop: "260px",
              paddingLeft: "5%",
            }}
            src={citron2}
            alt="img"
            width="15%"
          />
        </div>
      </span>
    </div>
  );
};
