import React from "react";
import { ImageWithDescription } from "../../components/image-with-description";
import sqr from "../../public/our-town/Senequier.jpeg";
import pdp from "../../public/our-town/Pan_Deï_Palais.jpg";
import bh from "../../public/our-town/Bahn-Hoi.jpg";
import lp from "../../public/our-town/La_Ponche.jpeg";
import lpr from "../../public/our-town/Le_Patio.jpeg";
import ga from "../../public/our-town/Golf_Azur.jpeg";
import mr from "../../public/our-town/Moorea.jpeg";
import slm from "../../public/our-town/Le_Salama.jpeg";
import tge from "../../public/our-town/Le_TiGrr.jpeg";
import ap from "../../public/our-town/Allo_Pizza.jpeg";
import dc from "../../public/our-town/Dior.jpg";
import bdp from "../../public/our-town/Bar_Du_Port.jpeg";
import cdlm from "../../public/our-town/Chateau_de_la_Messardière.jpeg";
import bdst from "../../public/our-town/Bastide_de_Saint_Tropez.jpeg";
import bbc from "../../public/our-town/Barbarac.jpeg";
import tam from "../../public/our-town/Auberge_des_Maurres.jpeg";
import pl from "../../public/our-town/Place_des_Lices.jpeg";
import ctd from "../../public/our-town/La_Citadelle.jpeg";
import pdg from "../../public/our-town/Plade_des_Graniers.jpg";
import pdc from "../../public/our-town/Plage_des_Canoubiers.jpeg";
import pp from "../../public/our-town/Pampelonne.jpeg";
import pds from "../../public/our-town/Plage_Salins.jpeg";
import cc from "../../public/our-town/Cap_Camarat.jpeg";
import csa from "../../public/our-town/Chapelle_Sainte_Anne.jpg";
import mrn from "../../public/our-town/Marinette.jpeg";
import pdsa from "../../public/our-town/Phare.jpeg";
import po from "../../public/our-town/Ormeauu.jpg";
import ph from "../../public/our-town/Place_aux_Herbes.jpeg";
import "./our-town.css";
import "react-multi-carousel/lib/styles.css";
import { ParagraphText } from "../../components/paragraph-text";
import { Gallery } from "./components/gallery";

const townImages = [
  <ImageWithDescription
    key={1}
    image={pdp}
    title="Le Pan Deï Palais"
    description="Tel un joyau caché au cœur de Saint-Tropez, le « Pan Deï ! » dévoile ses charmes d'antan, où l'élégance provençale danse avec l'opulence orientale. Sa piscine vous y attend pour devenir l’escale apéritive par excellence avant d’aller dîner."
    footerDescription="52 Rue Gambetta, Saint-Tropez"
    link="https://www.google.com/maps/place/Airelles+Saint-Tropez,+Pan+Dei+Palais/@43.270657,6.640969,15z/data=!4m9!3m8!1s0x12cec7153ff9d467:0x5d66ac569e4e5eff!5m2!4m1!1i2!8m2!3d43.270657!4d6.640969!16s%2Fg%2F1tf7ztc7?entry=ttu"
  />,
  <ImageWithDescription
    key={2}
    image={bh}
    title="Le BanH-HoÏ"
    description="Lieu de notre première « photo au restau », vous y embarquez pour un périple culinaire qui vous fera visiter les côtes du Golfe de Thaïlande jusqu’à la mer du Japon. "
    footerDescription="12 Rue du Petit Saint-Jean, Saint-Tropez"
    link="https://www.google.com/maps/place/Restaurant+BanH-Hoi/@43.2729244,6.6405748,15z/data=!4m2!3m1!1s0x0:0xef5704f981edbdd5?sa=X&ved=2ahUKEwjyvtiF_dWCAxV-AfsDHX4TC4UQ_BJ6BAhQEAA"
  />,
  <ImageWithDescription
    key={3}
    image={sqr}
    title="Sénéquier"
    description="Côté port : le Café Sénéquier et son mobilier rouge garance. Côté village : la pâtisserie, qui a connu les visites gourmandes quotidiennes à l’époque ou Édouard vivait place au Herbes. Une spécialité : le nougat blanc."
    footerDescription="29 Quai Jean Jaurès, Saint-Tropez"
    link="https://www.google.com/maps/place/S%C3%89N%C3%89QUIER/@43.2722678,6.6392421,15z/data=!4m6!3m5!1s0x12cec76b309ae769:0xba56084c89c9bb9b!8m2!3d43.2722678!4d6.6392421!16s%2Fg%2F11r80nm15?entry=ttu"
  />,
  <ImageWithDescription
    key={4}
    image={lp}
    title="La Ponche"
    description="Au bout de la balade qui débute depuis le vieux port de pêche, le ponton de la Ponche offre l’harmonie et l’essentiel de Saint-Tropez. Lieu haut en couleurs par son banc en mosaïque et un trésor caché à tout jamais…"
    footerDescription="4 Rue des Remparts, Saint-Tropez"
    link="https://www.google.com/maps/place/La+Ponche/@43.2732968,6.6382099,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec7828ca753d7:0xaef41b87529e59f4!8m2!3d43.2732968!4d6.6407848!16s%2Fg%2F11kj6qklsf?entry=ttu"
  />,
  <ImageWithDescription
    key={5}
    image={lpr}
    title="Le Patio"
    description="Franchissez la petite porte blanche et entrez dans l'univers paisible et isolé d’un jardin luxuriant. Pour un verre ou un dîner. Si vous avez du mal à trouver l'entrée, cherchez le beau palmier qui se trouve à proximité. Un des endroits préférés de la future mariée."
    footerDescription="1 Bd d'Aumale, Saint-Tropez"
    link="https://www.google.com/maps/place/Restaurant+Le+Patio+Saint-Tropez/@43.2725522,6.6412223,15z/data=!4m2!3m1!1s0x0:0x2dd47bd44b23dc73?sa=X&ved=2ahUKEwjk0fbG_tWCAxUCTaQEHbjbBWEQ_BJ6BAhZEAA"
  />,
  <ImageWithDescription
    key={8}
    image={slm}
    title="Le Salama"
    description="Aussi connu pour ses tajines que pour les Instagrammeuses qui y ont passé leurs derniers étés, le Salama est la promesse d’un voyage lointain et festif, à travers les Terres du Sud."
    footerDescription="1 Chem. des Conquettes, Saint-Tropez"
    link="https://www.google.com/maps/place/Restaurant+Salama+Saint-Tropez/@43.2674495,6.6391922,15z/data=!4m6!3m5!1s0x12cec714981a54d1:0xf2d311516d867df9!8m2!3d43.2674495!4d6.6391922!16s%2Fg%2F1tvynvtn?entry=ttu"
  />,

  <ImageWithDescription
    key={7}
    image={tge}
    title="Le TIGrr – l’Ermitage"
    description="A l’instar du rugissement du Roi Lion au lever du soleil, c’est le TIGrr qui accompagne le mieux son coucher derrière le massif des Maurres, un Kung-Fu Panda à la main."
    footerDescription="14 Av. Paul Signac, 83990 Saint-Tropez"
    link="https://www.google.com/maps/place/LE+TIGRR+Ermitage/@43.2710477,6.6402731,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec73fa400adbd:0x48b6cf5a6c3cfa62!8m2!3d43.2710477!4d6.642848!16s%2Fg%2F11cn0n1vzg?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={ap}
    title="Allo Pizza"
    description="Dressé tel un phare de simplicité au bord de la route des Plages, ce camion à pizza offre l’alternative d’une cuisine simple. Lieux de dégustation recommandés: plage de la Bouillabaisse ou la chapelle Sainte-Anne."
    footerDescription="Rte des Plages, Saint-Tropez"
    link="https://www.google.com/maps/place/Allo+Pizza+St-Tropez/@43.2623316,6.615625,13.14z/data=!4m6!3m5!1s0x12cec71b35e86ff3:0xf355db1b15ed2ba4!8m2!3d43.2559111!4d6.6356878!16s%2Fg%2F1tjjfp_x?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={dc}
    title="DIOR Café"
    description="« DIOR, ils font de la haute-couture, pas de la restauration ?! ». Une fois ce préjugé exhalé, laissez-vous tenter par l’idée d’une tartelette de tomates et sorbet gaspacho, de courgettes trompettes et sabayon à l'oseille, d’un filet de bœuf au sautoir, ou d’un raviole de cèpes d'été et crème de laitue."
    footerDescription="13 Rue François Sibilli, Saint-Tropez"
    link="https://www.google.com/maps/place/DIOR+Caf%C3%A9+des+Lices/@43.2709426,6.6378342,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec71522bbb87b:0x189f74d15bfb86c2!8m2!3d43.2709426!4d6.6404091!16s%2Fg%2F11cknwkqbl?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={bdp}
    title="Le Bar du Port"
    description="Ou plutôt le « BDP » pour les intimes. Une adresse du port à privilégier pour l’apéritif ou la pré-soirée. Dites que vous venez de la part de Calista, et vous aurez la meilleure table en terrasse."
    footerDescription="7 Quai Suffren, Saint-Tropez"
    link="https://www.google.com/maps/place/Bar+du+Port/@43.2719299,6.6367417,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec714992e943f:0xca416e3223db92cc!8m2!3d43.2719299!4d6.6393166!16s%2Fg%2F113dndzxn?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={cdlm}
    title="Le Château de la Messardière"
    description="Fièrement campé sur sa colline, ce manoir du XIXe siècle qui domine la baie de Pampelonne et le golfe de Saint-Tropez mérite le détour, et voire une nuitée pour les plus audacieux."
    footerDescription="2 Rte de Tahiti, Saint-Tropez"
    link="https://www.google.com/maps/place/Airelles+Saint-Tropez,+Ch%C3%A2teau+de+La+Messardi%C3%A8re/@43.2511974,6.6520553,15.12z/data=!4m9!3m8!1s0x12cec72602a6ebc5:0x2ecc6386faf841fe!5m2!4m1!1i2!8m2!3d43.2542715!4d6.657846!16s%2Fg%2F11dyl6chz?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={bdst}
    title="La Bastide de Saint-Tropez"
    description="À 10 minutes à pied du centre du village, cet hôtel composé de plusieurs mas donne une impression d’intimité dès sa réception. Les chambres enfouies dans la végétation entourent la piscine, cette dernière étant surplombée par l’excellent restaurant « L’Olivier »."
    footerDescription="Rte des Carles, Saint-Tropez"
    link="https://www.google.com/maps/place/H%C3%B4tel+La+Bastide+de+Saint-Tropez/@43.2622615,6.6347183,17z/data=!3m1!4b1!4m10!3m9!1s0x12cec710d6b91145:0x17fae5a49d15a7e4!5m3!1s2024-04-26!4m1!1i2!8m2!3d43.2622615!4d6.6372932!16s%2Fg%2F1tg97kmd?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={bbc}
    title="Barbarac"
    description="Lieu de naissance d’une passion « banane-fraise » pour Édouard, c’est le point fraîcheur incontournable du port de Saint-Tropez. Concurrent sentimental d’un certain Tinou."
    footerDescription="2 Rue Général Allard, Saint-Tropez"
    link="https://www.google.com/maps/place/Barbarac/@43.2713615,6.6359971,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec714c66403a3:0x7374a2fef9e38ca8!8m2!3d43.2713615!4d6.638572!16s%2Fg%2F1wcj_zj5?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={tam}
    title="Le Traiteur de l’Auberge des Maures"
    description="Tout simplement un hymne gustatif à la Provence, où l’on retrouve les farcis, les artichauts barigoule et les beignets de fleurs de courgette, le filet de daurade et la daube, et plus encore."
    footerDescription="Pl. Alphonse Celli, Saint-Tropez"
    link="https://www.google.com/maps/place/Le+Traiteur+de+l'Auberge+des+Maures/@43.2708337,6.6342719,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec75e90d7e2f1:0x90ac3801c5ca4e05!8m2!3d43.2708337!4d6.6368468!16s%2Fg%2F11rfndl0v0?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={pl}
    title="La Place des Lices"
    description="Chaque mardi et chaque samedi matin, elle accueille un charmant marché provençal où Morgane se plaît à essayer une multitude de bijoux fantaisie. Arborée de platanes, c’est aussi l’endroit favori des boulistes tropéziens pour tirer ou pointer à la fraîche."
    footerDescription="Trav. des Lices, Saint-Tropez"
    link="https://www.google.com/maps/place/Place+des+Lices/@43.2699486,6.6385949,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec71572ca72b9:0x4c72b45263c545dc!8m2!3d43.2699486!4d6.6411698!16s%2Fm%2F0k75kby?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={ctd}
    title="La Citadelle"
    description="La Citadelle est une invitation à prendre de la hauteur sur le tumulte tropézien, et offre parmi les plus belles vues sur le village."
    footerDescription="1 Mnt de la Citadelle, Saint-Tropez"
    link="https://www.google.com/maps/place/Citadelle/@43.2723937,6.6415905,17z/data=!4m6!3m5!1s0x12cec74005b0e6bd:0x6c3653e9c90fcd7b!8m2!3d43.2723937!4d6.6441654!16s%2Fg%2F11h1tx6jb?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={csa}
    title="La Chapelle Saint-Anne"
    description="Vous aurez plus de chance d’y croiser Carmen que de la voir ouverte au public. Une halte s’impose pour y apercevoir le village sous un autre angle, avec les premiers sommets des Alpes en toile de fond."
    footerDescription="21 Chem. des Bastidettes, Saint-Tropez"
    link="https://www.google.com/maps/place/Chapelle+Sainte-Anne/@43.259591,6.6394259,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec71886941cc5:0x1c26ad93ba5a493c!8m2!3d43.259591!4d6.6420008!16s%2Fg%2F11dxs99_8q?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={mrn}
    title="Marinette"
    description="Il n’existe pas une escale tropézienne sans que Morgane aille y chiner la prochaine pièce qui viendra parfaire notre panoplie de linge de maison."
    footerDescription="30 Rue Georges Clemenceau, Saint-Tropez"
    link="https://www.google.com/maps/place/Marinette+D%C3%A9coration+Saint+Tropez/@43.2698647,6.6333312,17z/data=!3m1!5s0x12cec714e6d303d7:0x33f02039b6e57b7c!4m10!1m2!2m1!1sMarinette,+Saint-Tropez!3m6!1s0x12cec714c413c2d7:0x45d9066af55f95f!8m2!3d43.2706568!4d6.6394242!15sChdNYXJpbmV0dGUsIFNhaW50LVRyb3BleloYIhZtYXJpbmV0dGUgc2FpbnQgdHJvcGV6kgEQaG9tZV9nb29kc19zdG9yZeABAA!16s%2Fg%2F1td22n7l?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={po}
    title="La Place de l’Ormeau"
    description="Une carte postale: un gigantesque bougainvillier se faufilant entre les pierres d’une ancienne bâtisse, des bouquets de fleurs séchées et une vue sur le clocher en contre-plongée qui prend une tout autre dimension par un ciel d’un azur profond."
    footerDescription="Pl. de l'ormeau, Saint-Tropez"
    link="https://www.google.com/maps/place/Pl.+de+l'Ormeau,+83990+Saint-Tropez/@43.272665,6.6379511,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec76ac51dabc7:0xbcd7ea5b772a0793!8m2!3d43.272665!4d6.640526!16s%2Fg%2F1tnzh92k?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={ph}
    title="La Place aux Herbes"
    description="La place aux Herbes est un tableau vivant: c'est un cœur battant, un reflet vibrant de l'âme du Saint-Tropez d’Édouard, où chaque moment passé, chaque éclat de rire est un trésor d'émotions et de souvenirs impérissables."
    footerDescription="Pl. aux herbes, Saint-Tropez"
    link="https://www.google.com/maps/place/Pl.+aux+Herbes,+83990+Saint-Tropez/@43.2723021,6.6368497,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec76b29531701:0xdefdb288e36dfec0!8m2!3d43.2723021!4d6.6394246!16s%2Fg%2F1vc69m8p?entry=ttu"
  />,
];

const beachImages = [
  <ImageWithDescription
    key={6}
    image={ga}
    title="Golfe Azur"
    description="Ambiance ginguette de plage, pour un déjeuner les pieds dans le sable, avec une vue imprenable sur le Golfe de Saint-Tropez. En toute simplicité !"
    footerDescription=" Quartier Bouillabaisse, Saint-Tropez"
    link="https://www.google.com/maps/place/Le+Golfe+Azur/@43.2660964,6.6249821,15z/data=!4m6!3m5!1s0x12cec71512922f0b:0x593fbb1ffd5ba3ef!8m2!3d43.2660964!4d6.6249821!16s%2Fg%2F1tfljmyf?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={mr}
    title="Moorea"
    description="Cette adresse de Pampelonne a été le théâtre du premier job étudiant d’Édouard, Simplicité et convivialité caractérisent cette plage-restaurant. Si vous croisez Christophe, le patron, passez-lui le bonjour !"
    footerDescription="Chem. des Moulins, Ramatuelle"
    link="https://www.google.com/maps/place/Moorea/@43.2458673,6.639398,13.41z/data=!4m6!3m5!1s0x12cec6d4f5b84829:0x7ec28bcd193fdf80!8m2!3d43.2420222!4d6.6641181!16s%2Fg%2F1w0mdv5h?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={pdg}
    title="La Plage des Graniers"
    description="Située au pied de la Citadelle, après le cimetière marin, cette petite crique de sable à l’eau limpide et ombragée a conservé un côté sauvage et naturel à deux pas du village."
    footerDescription="Plage des greniers, Saint-Tropez"
    link="https://www.google.com/maps/place/Plage+des+Graniers/@43.2705927,6.609213,14z/data=!3m1!4b1!4m6!3m5!1s0x12cec738b6b4ef55:0xf40ce27ef250cb7f!8m2!3d43.2705998!4d6.6481815!16s%2Fg%2F11f3s8n8gz?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={pdc}
    title="La Plage des Canoubiers"
    description="Laure, Caroline et Jessica passaient leur journée sur cette plage partiellement ombragée par quelques pins parasols. Vous l’avez ?"
    footerDescription="Plage des canoubiers, Saint-Tropez"
    link="https://www.google.com/maps/place/Plage+des+Canoubiers/@43.2651792,6.6555266,15z/data=!3m1!4b1!4m6!3m5!1s0x12cec0cc852d777b:0x5fc764ac31d8b45b!8m2!3d43.2651802!4d6.6658263!16s%2Fg%2F11g0mv8395?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={pp}
    title="La Plage de Pampelone"
    description="Au cœur de la saison estivale, c’est dans cette anse de 5 kilomètres de long que sont débouchées le plus de bouteilles de champagnes et rosés. Tout aussi agréable pour une promenade au petit matin. Deux salles deux ambiances."
    footerDescription="Plage de Pampelone, Ramatuelle"
    link="https://www.google.com/maps/place/Plage+de+Pampelonne/@43.2264736,6.6432112,14z/data=!3m1!4b1!4m6!3m5!1s0x12cec6b4dd5567cd:0xa6708424604036aa!8m2!3d43.227051!4d6.6621848!16s%2Fg%2F121d1jnx?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={pds}
    title="La Plage des Salins"
    description="À l’opposé des Canoubiers, c’est aussi une plage où les Tropéziens aiment poser leurs foutas, à l’abris des regards et de la fête."
    footerDescription="Plage des salins, Saint-Tropez"
    link="https://www.google.com/maps/place/Plage+des+Salins/@43.258661,6.6820083,15z/data=!4m10!1m2!2m1!1sLa+Plage+des+Salins,+Saint-Tropez!3m6!1s0x12cec0e591c2c491:0x60a435857065aa41!8m2!3d43.258662!4d6.692308!15sCiFMYSBQbGFnZSBkZXMgU2FsaW5zLCBTYWludC1Ucm9wZXqSAQViZWFjaOABAA!16s%2Fg%2F1v_swjjf?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={cc}
    title="Le Cap Camarat"
    description="Saint-Cast possède Notre-Dame-de-la-Garde, Saint-Tropez se console avec le Cap Camarat. Vue plongeante sur la baie de Pampelonne, et pour les plus sportifs: col de 2ème catégorie pour y arriver et point de départ pour un trail vue Méditerranée."
    footerDescription="Le cap camarat, Ramatuelle"
    link="https://www.google.com/maps/place/Cap+Camarat/@43.199978,6.6747433,15z/data=!4m10!1m2!2m1!1sLe+Cap+Camarat,+Saint-Tropez!3m6!1s0x12cec3e828544993:0xe8114aee4f7a25a8!8m2!3d43.199979!4d6.685043!15sChxMZSBDYXAgQ2FtYXJhdCwgU2FpbnQtVHJvcGV6Wh0iG2xlIGNhcCBjYW1hcmF0IHNhaW50IHRyb3BlepIBCXBlbmluc3VsYeABAA!16s%2Fg%2F112yfs817?entry=ttu"
  />,
  <ImageWithDescription
    key={7}
    image={pdsa}
    title="Le Phare de Saint-Tropez"
    description="En plus du phare que nous sommes l’un pour l’autre, celui du bout de la jetée du port vaut le détour et offre un point de vue sur tout le golfe de Saint-Tropez."
    footerDescription="Phare, Saint-Tropez"
    link="https://www.google.com/maps/place/Phare+de+Saint-Tropez/@43.2727104,6.6299973,17z/data=!3m1!4b1!4m6!3m5!1s0x12cec76d4dd50c3f:0xb3e6f150a726fc8c!8m2!3d43.2727104!4d6.6325722!16s%2Fg%2F121_jmp8?entry=ttu"
  />,
];

export const OurTown = () => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <ParagraphText
        lines={["Notre Saint-Tropez"]}
        style={{
          fontFamily: "siteFont",
          fontSize: "clamp(40pt, 6vw, 55pt)",
          lineHeight: "5px",
          height: "120px",
        }}
      />
      <ParagraphText
        lines={[
          "Pour vous aider à découvrir le village d’Edouard, nous vous avons listé les endroits",
          "où nous préférons nous balader, manger des spécialités locales ou boire un verre.",
          "Nous espérons que vous aimerez autant que nous cette perle de la Provence.",
        ]}
        style={{
          lineHeight: "5px",
          paddingBottom: "15px",
          fontSize: "clamp(11px, 1.5vw, 15px)",
        }}
      />
      <div>
        <div style={{ paddingLeft: "8%" }}>
          <ParagraphText
            lines={["Coté centre ville"]}
            style={{
              fontFamily: "siteFont",
              fontSize: "clamp(40pt, 6vw, 55pt)",
              lineHeight: "5px",
              textAlign: "left",
            }}
          />
        </div>
        <Gallery photos={townImages} />
        <div
          style={{
            paddingInline: "8%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <ParagraphText
            lines={["Coté mer et plage"]}
            style={{
              fontFamily: "siteFont",
              fontSize: "clamp(40pt, 6vw, 55pt)",
              lineHeight: "5px",
              textAlign: "right",
              paddingTop: "10px",
            }}
          />
        </div>
        <Gallery photos={beachImages} />
      </div>
    </div>
  );
};
