import React from "react";
import "../../App.css";
import { ParagraphText } from "../../components/paragraph-text";
import "./transport-detail.css";

export const TransportDetail = ({
  transportImg,
  imgSide = "left",
  title,
  descriptionParagraph,
  textAlign,
  link,
}) => {
  return (
    <div
      className={transportImg ? "detail-container" : "detail-container-center"}
      style={{
        flexDirection: imgSide === "right" ? "row-reverse" : "",
        textAlign: textAlign || "",
      }}
    >
      {transportImg && transportImg()}
      <div
        className={
          transportImg
            ? "description-container"
            : "description-container-center"
        }
        style={{ fontSize: "clamp(10px, 1.5vw, 15px)" }}
      >
        <ParagraphText
          lines={[title]}
          className={
            transportImg
              ? "description-container"
              : "description-container-center"
          }
          style={{
            fontWeight: "bold",
            paddingBottom: "1px",
          }}
        />
        {descriptionParagraph.map((dp, i) => (
          <ParagraphText key={i} lines={dp} />
        ))}
        {link && (
          <a target="_blank" rel="noopener noreferrer" href={link.url}>
            {link.title}
          </a>
        )}
      </div>
    </div>
  );
};
