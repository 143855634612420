import React from 'react';
import "./gallery.css";

export const Gallery = ({ photos }) => {
  return (
    <div style={{ width:"100%", display: 'flex', flexWrap: 'wrap', gap: '15px', justifyContent: "center" }}>
      {photos.map((photo, index) => (
        <div key={index} className="gallery-item" style={{borderRadius: "5px", transition: 'transform 0.3s ease-in-out'}}>
          {photo}
        </div>
      ))}
    </div>
  );
};