import React from "react";
import "../../App.css";
import { ParagraphText } from "../../components/paragraph-text";
import olive_2 from "../../public/accommodation/olive_2.png";
import olive from "../../public/accommodation/olive.png";
import orange from "../../public/accommodation/orange.png";
import orange_2 from "../../public/accommodation/orange_2.png";
import taxi from "../../public/accommodation/taxi.png";
import one from "../../public/accommodation/rooms/1.jpg";
import four from "../../public/accommodation/rooms/4.jpg";
import five from "../../public/accommodation/rooms/5.jpeg";
import "./components/banner.css";
import "./accomodation.css";

export const Accommodation = () => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <ParagraphText
          lines={["Où dormir la veille du mariage"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(32pt, 6vw, 55pt)",
            lineHeight: "5px",
          }}
        />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className="side-img"
            style={{
              paddingTop: "30px",
              height: "fit-content",
              paddingRight: "2%",
            }}
            src={olive_2}
            alt="img"
            width="15%"
          />
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ParagraphText
              lines={[
                "Si vous souhaitez déjà être sur place la veille du mariage",
                "et gagner en quiétude le samedi, nous vous conseillons de choisir",
                "un hébergement dans l’une des communes suivantes:",
                "la Croix-Valmer, Cavalaire, Ramatuelle, Saint-Tropez,",
                "Grimaud ou Sainte-Maxime.",
              ]}
              style={{
                lineHeight: "5px",
                paddingBottom: "10px",
                fontSize: "clamp(10px, 1.5vw, 15px)",
              }}
            />
            <ParagraphText
              lines={[
                "Pensez à réserver le plus tôt possible pour bénéficier des meilleurs prix.",
              ]}
              style={{
                lineHeight: "5px",
                paddingTop: "10px",
                paddingBottom: "10px",
                fontSize: "clamp(10px, 1.5vw, 15px)",
              }}
            />
            <ParagraphText
              lines={["Où dormir le jour du mariage"]}
              style={{
                fontFamily: "siteFont",
                fontSize: "clamp(33pt, 6vw, 55pt)",
                lineHeight: "5px",
              }}
            />
          </span>

          <img
            className="side-img"
            style={{
              height: "fit-content",
              paddingTop: "220px",
              paddingLeft: "5%",
            }}
            src={orange}
            alt="img"
            width="10%"
          />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            className="side-img"
            style={{
              height: "fit-content",
              paddingRight: "5%",
              paddingTop: "150px",
            }}
            src={orange_2}
            alt="img"
            width="12%"
          />
          <span
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <ParagraphText
              lines={[
                "Nous pouvons loger jusqu’à 110 invités sur notre lieu de réception.",
                "Tous nos invités ne pourront malheureusement pas profiter d’une chambre",
                "double mais nous avons plusieurs grandes chambres (de 4 à 10 personnes)",
                "et des tipis de luxe pouvant accueillir 2 à 4 personnes.",
              ]}
              style={{
                lineHeight: "5px",
                paddingBottom: "10px",
                fontSize: "clamp(10px, 1.5vw, 15px)",
              }}
            />
            <ParagraphText
              lines={[
                "Nous demanderons à nos invités souhaitant loger sur place",
                "une participation de maximum 65€ par personne.",
                "(en fonction du caractère privatif, ou non, de la chambre)",
              ]}
              style={{
                lineHeight: "5px",
                paddingBottom: "10px",
                fontSize: "clamp(10px, 1.5vw, 15px)",
              }}
            />
            <ParagraphText
              lines={[
                "La répartition par chambre vous sera communiquée 2 mois avant le mariage",
                "et nous tacherons de respecter l’intimité de chacun, et éviterons de faire",
                "dormir dans la même chambre des personnes qui ne se connaissent pas.",
                "Nous laissons l’ambiance du mariage se charger du reste !",
              ]}
              style={{
                lineHeight: "5px",
                paddingBottom: "15px",
                fontSize: "clamp(10px, 1.5vw, 15px)",
              }}
            />
            <ParagraphText
              lines={[
                "Vous pourrez récupérer vos chambres dès votre arrivée sur le lieu",
                "avant le début du cocktail entre 17h et 18h.",
                "Les chambres comprennent tout le linge de maison requis (lit et bain)",
              ]}
              style={{
                lineHeight: "5px",
                paddingBottom: "15px",
                fontSize: "clamp(10px, 1.5vw, 15px)",
              }}
            />
          </span>
          <img
            className="side-img"
            style={{
              height: "fit-content",
              paddingLeft: "5%",
              paddingTop: "220px",
            }}
            src={olive}
            alt="img"
            width="8%"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            justifyContent: "center",
            paddingBottom: "50px",
            paddingTop: "50px",
          }}
        >
          <img className="banner-img" src={four} alt={"un"} />
          <img className="banner-img" src={one} alt={"un"} />
          <img className="banner-img" src={five} alt={"un"} />
        </div>
        <div style={{ textAlign: "center", display: "grid" }}>
          <ParagraphText
            lines={[
              "Pour les invités ne souhaitant pas dormir sur place,",
              "aucun problème, nous ne nous vexerons pas.",
              "Voici quelques hébergements que nous avons sélectionnés pour vous,",
              "à proximité du Domaine de Franco :",
            ]}
            style={{
              lineHeight: "5px",
              paddingBottom: "15px",
              fontSize: "clamp(10px, 1.5vw, 15px)",
            }}
          />
          <div style={{ display: "inline-grid", justifyContent: "center" }}>
            <div>
              <a
                style={{ width: "fit-content" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://direct-brignoles-provence-verte.kyriad.com/fr-fr/"
              >
                Kyriad Direct Brignoles
              </a>
            </div>
            <div>
              <a
                style={{ width: "fit-content" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.hoteldeprovencebrignoles.fr/"
              >
                L’Hôtel de Provence
              </a>
            </div>
            <div>
              <a
                style={{ width: "fit-content" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://hotel.vueling.com/hotel/fr/le-domaine-de-l-olivier-centre-de-bien-etre-solidaire.fr.html"
              >
                Le Domaine de l’olivier
              </a>
            </div>
            <div>
              <a
                style={{ width: "fit-content" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.bastide-saint-julien.com/"
              >
                Bastide de Saint-Julien
              </a>
            </div>
            <div>
              <a
                style={{ width: "fit-content" }}
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.lescollectionneurs.com/hostellerie-de-l-abbaye-de-la-celle-2063"
              >
                Hostellerie de l'Abbaye de la Celle
              </a>
            </div>
          </div>
        </div>
        <img
          style={{ paddingTop: "30px" }}
          src={taxi}
          alt="img"
          width={170}
          height={150}
        />
        <ParagraphText
          lines={[
            "Il est important de noter que nous ne prévoyons pas de navettes ou taxis",
            "pour les personnes souhaitant dormir à l’extérieur du Domaine.",
          ]}
          style={{
            lineHeight: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontWeight: "bold",
            fontSize: "clamp(9px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={[
            "Néanmoins, nous restons soucieux de votre sécurité.",
            "C’est pourquoi nous ne laisserons aucun(e) invité(e) quitter la soirée",
            "en état d’ébriété. L’équipe de surveillance du Domaine se",
            "chargera de contrôler les départs des invités.",
            "Mais pas de panique, voici la liste des taxis capables de",
            "vous ramener en toute sécurité chez vous:",
          ]}
          style={{
            lineHeight: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={[
            "Taxi Nicolas Gardiol : +33 4 94 69 08 21",
            "LBC Taxi : +33 6 61 87 82 32",
            "Taxi Romain : +33 6 72 46 85 23",
          ]}
          style={{
            lineHeight: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
      </span>
    </div>
  );
};
