import React from "react";
import "../../App.css";
import { ParagraphText } from "../../components/paragraph-text";
import afrique from "../../public/wedding-list/afrique.jpeg";

export const WeddingList = () => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <ParagraphText
          lines={["Notre liste de mariage"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(40pt, 6vw, 55pt)",
            lineHeight: "5px",
            height: "120px",
          }}
        />
        <ParagraphText
          lines={[
            "Votre présence à notre mariage et le temps que nous passerons ensemble",
            "seront à n’en pas douter notre plus beau cadeau.",
          ]}
          style={{
            lineHeight: "5px",
            paddingBottom: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={[
            "Toutefois, si vous souhaitez également être complices de nos futures",
            "escapades en Afrique pour notre lune de miel, une urne sera mise à la",
            "disposition de nos invités le jour du mariage.",
          ]}
          style={{
            lineHeight: "5px",
            paddingBottom: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={[
            "Vous avez aussi la possibilité d’effectuer dès à",
            "présent un virement bancaire au numéro de compte suivant :",
          ]}
          style={{
            lineHeight: "5px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={["Titulaire du compte : M. TIENVROT ou Mlle EVAIN"]}
          style={{
            lineHeight: "5px",
            paddingTop: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
            textAlign: "left",
            fontWeight: "bold",
          }}
        />

        <ParagraphText
          lines={[
            "Code B.I.C: BOUS FRPP XXX",
            "Code I.B.A.N: FR76 4061 8803 4600 0403 8878 075",
            "Code Banque: 40618",
            "Code Guichet: 80346",
          ]}
          style={{
            lineHeight: "5px",
            marginLeft: "5px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
            textAlign: "left",
            fontWeight: "bold",
          }}
        />
        <img
          style={{
            marginTop: "30px",
            borderRadius: "10px",
            height: "auto",
          }}
          src={afrique}
          alt="img"
          width="50%"
          height="50%"
        />
      </span>
    </div>
  );
};
