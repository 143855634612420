import React from "react";

export function ParagraphText({ lines, style, className, required = false }) {
  return (
    <div
      className={className || ""}
      style={{ display: "flex", alignItems: "baseline" }}
    >
      <div
        style={{
          width: "fit-content",
          minWidth: "max-content",
          height: "fit-content",
          ...style,
        }}
      >
        {lines.map((line, i) => (
          <p key={"p-" + i}>{line}</p>
        ))}
      </div>
      {required && (
        <span style={{ color: "red", fontWeight: "bold", paddingLeft: "5px" }}>
          *
        </span>
      )}
    </div>
  );
}
