import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB40BhGcz6rg8Dyodbfv6QYthWysLdnfrY",
  authDomain: "wedding-ganou-doudou.firebaseapp.com",
  projectId: "wedding-ganou-doudou",
  storageBucket: "wedding-ganou-doudou.appspot.com",
  messagingSenderId: "819979918917",
  appId: "1:819979918917:web:cb735937d4b88a81b30d2c"
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);

export const db = app.firestore();
