import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import logo from "../public/logo.png";
import background from "../public/background.jpg";
import "./index.css";
import { routes } from "./constants";
import { ScrollToTop } from "../components/scroll-to";
import TableRowsRoundedIcon from "@mui/icons-material/TableRowsRounded";

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation(); // once ready it returns the 'window.location' object

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  function goHome() {
    navigate("/date-et-lieu");
  }

  function toggleMenu() {
    setMenuIsOpen((open) => !open);
  }

  const url = location.pathname;

  return (
    <div
      className="mainContainer"
      style={{ backgroundImage: `url(${background})` }}
    >
      <ScrollToTop />
      <nav className="navigation">
        <div className={`nav-bar-container ${menuIsOpen ? "is-open" : ""}`}>
          <div className={`nav-bar  ${menuIsOpen ? "is-open" : ""}`}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                setMenuIsOpen(false);
                goHome();
              }}
            >
              <img
                src={logo}
                className="logo"
                alt="logo"
                width={150}
                height={150}
              />
            </div>
            <span className="nav-list-icon-mobile" onClick={toggleMenu}>
              <TableRowsRoundedIcon fontSize="large" />
            </span>
          </div>

          <ul className={`nav-list ${menuIsOpen ? "is-open" : ""}`}>
            <li
              className={url === routes.date ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.date}>Date & lieu</Link>
            </li>
            <li
              className={url === routes.program ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.program}>Programme</Link>
            </li>
            <li
              className={url === routes.transport ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.transport}>Transport</Link>
            </li>
            <li
              className={url === routes.accommodation ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.accommodation}>Hébergement</Link>
            </li>
            <li
              className={url === routes.city ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.city}>Notre Saint-Tropez</Link>
            </li>
            <li
              className={url === routes.weeding ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.weeding}>Liste de mariage</Link>
            </li>
            <li
              className={url === routes.faq ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.faq}>FAQ</Link>
            </li>
            <li
              className={url === routes.rsvp ? "active-tab" : ""}
              onClick={() => setMenuIsOpen(false)}
            >
              <Link to={routes.rsvp}>RSVP</Link>
            </li>
          </ul>
        </div>
      </nav>
      <div className="nav-body">
        <Outlet />
      </div>
    </div>
  );
};

export default Navigation;
