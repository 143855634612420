import React from "react";
import { faqContent } from "./constants";
import FaqList from "react-faq-component";
import { ParagraphText } from "../../components/paragraph-text";
import "./faq.css";

const faqData = {
  rows: faqContent,
};

const config = {
  openOnload: true,
  animate: true,
  arrowIcon: "V",
  tabFocus: true,
};

export const Faq = () => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <ParagraphText
          lines={["Vos questions les plus frequentes"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(30pt, 6vw, 55pt)",
            lineHeight: "5px",
            paddingBottom: "20px",
          }}
        />
        <div style={{ width: "80%" }}>
          <FaqList
            data={faqData}
            config={config}
            styles={{
              bgColor: "transparent",
              rowTitleTextSize: "clamp(10px, 1.5vw, 16px)",
              rowContentTextSize: "clamp(10px, 1.5vw, 15px)",
            }}
          />
        </div>
      </span>
    </div>
  );
};
