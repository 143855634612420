import React from "react";
import {createBrowserRouter, Navigate, RouterProvider} from "react-router-dom";
import ErrorPage from "./error-page";
import { DateSite } from "./screens/date-site";
import "./App.css";
import {Program} from "./screens/program";
import {Transport} from "./screens/transport";
import {Accommodation} from "./screens/accommodation";
import Navigation from "./navigation";
import {WeddingList} from "./screens/wedding-list";
import {Faq} from "./screens/faq/faq";
import {OurTown} from "./screens/our-town";
import {Form} from "./screens/form/form";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigation />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "",
        element: <Navigate to="/date-et-lieu" replace/>
      },
      {
        path: "/date-et-lieu",
        element: <DateSite />,
      },
      {
        path: "/programme",
        element: <Program />,
      },
      {
        path: "/transport",
        element: <Transport />,
      },
      {
        path: "/hebergement",
        element: <Accommodation />,
      },
      {
        path: "/liste-de-mariage",
        element: <WeddingList />,
      },
      {
        path: "/saint-tropez",
        element: <OurTown />,
      },
      {
        path: "/faq",
        element: <Faq />,
      },
      {
        path: "/rsvp",
        element: <Form />,
      },
    ],
  },
]);

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
