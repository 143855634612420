export const faqContent = [
  {
    title:
      "Combien de jour dure le mariage ? Devons-nous participer à l’intégralité ?",
    content: `Notre mariage dure deux jours : le samedi 7 septembre 2024 pour la cérémonie et la réception, et le dimanche 8 septembre pour le brunch et activités. Vous n’êtes pas obligés d’être présents sur les deux journées et vous pouvez repartir quand vous le souhaitez. Dites-le-nous simplement soit en remplissant le formulaire dans l’onglet « RSVP » sur le site, en nous envoyant un e-mail ou par courrier à notre adresse : 72 Boulevard du Fier, 74000 Annecy`,
  },
  {
    title: "Quel est le code vestimentaire ?",
    content:
      "Pour notre mariage, nous souhaitons une ambiance élégante : costumes pour les hommes, tenues chics pour les femmes. Pas de code couleur imposé, juste votre style et votre prestance. Le blanc, et ses proches déclinaisons, sont bien évidemment proscrits le samedi et réservés pour la mariée. Et nous aimerions éviter le jaune -  que nous réservons pour le soleil ! Bien que septembre marque la fin de l’été, prévoyez des vêtements légers pour la journée et de quoi couvrir vos épaules une fois la nuit tombée.",
  },
  {
    title: "Jusqu’à quelle date puis-je confirmer ma présence ?",
    content: `Vous avez jusqu’au 7 juin 2024 pour vous confirmer votre présence. Soit en remplissant le formulaire dans l’onglet « RSVP » sur le site, en nous envoyant un e-mail ou par courrier à notre adresse : 72 Boulevard du Fier, 74000 Annecy`,
  },
  {
    title: "Comment confirmer ma présence ?",
    content: `Soit en remplissant le formulaire dans l’onglet « RSVP » sur le site, soit par email ou par courrier à notre adresse : 72 Boulevard du Fier, 74000 Annecy`,
  },
  {
    title: "Puis-je venir avec des enfants ?",
    content: `Nous aimons beaucoup les enfants et les vôtres en particulier, mais par mesure de sécurité et sérénité nous ne pourrons les accueillir à l’occasion de notre mariage. Notre lieu de réception est un domaine de 45 hectares avec ruisseaux, murs, forets (presque comme Koh-Lanta) et profite d’une grande piscine sans surveillance. L’espace d’un week-end, nous avons donc fait le choix de privilégier les grands enfants que vous êtes et nous vous invitons d’ores-et-déjà à prévenir papi, mamie, le/la baby-sitter ou n’importe quelle personne capable de s’occuper comme il se doit de vos enfants.`,
  },
  {
    title: "Est-il possible de ne pas venir en voiture ?",
    content: `La voiture est vivement recommandée pour se déplacer facilement entre votre gare/aéroport d’arrivée, Saint-Tropez et notre lieu de réception. Nous prévoyons de mettre en place un système de covoiturage avec nos invités pour faciliter le transport de ceux qui ne pourraient pas avoir de voiture. N’hésitez pas à nous le mentionner dans le formulaire de réponse. Un groupe WhatsApp sera créé prochaine pour faciliter le transport.`,
  },
  {
    title: "Le logement au Domaine de Franco est-il payant ?",
    content: `Oui, la participation est de maximum 65€ par personne la nuit (le prix varie en fonction du caractère privatif, ou non, de la chambre). Le lieu de réception peut accueillir jusqu’à 110 invités. Nous prévoyons donc de loger un maximum, voire la totalité de nos invités sur place. Nous ne pourrons pas faire profiter tout le monde de chambre double et nous privilégierons en premier nos familles (si si !)`,
  },
  {
    title: "Quand allons-nous récupérer notre chambre au Domaine de Franco ?",
    content: `Si vous faites le choix de dormir sur place, vous récupérerez votre chambre à votre arrivée sur le lieu de réception. Une personne dédiée vous indiquera votre chambre et son emplacement.`,
  },
  {
    title: "Les chambres disposent-elles de linge de maison ?",
    content: `Oui, le linge de maison (lit et bain) sont prévus dans toutes les chambres et tipis. Pour la piscine, nous vous demandons de bien vouloir prévoir votre serviette.`,
  },
  {
    title:
      "Qui contacter si je souhaite faire une surprise ou un discours aux mariés ?",
    content: `Vous pouvez contacter Marine Evain, la sœur et témoin de Morgane, au +33 6 67 05 30 70.`,
  },
  {
    title: "Comment prévenir de mes allergies culinaires ?",
    content: `Soit en remplissant le formulaire dans l’onglet « RSVP » sur le site, en nous envoyant un e-mail ou par courrier à notre adresse : 72 Boulevard du Fier, 74000 Annecy`,
  },
  {
    title: "Le cocktail et dîner sont-ils végétariens ?",
    content: `Le cocktail et dîner offrent une option végétarienne. Vous pouvez indiquer vos préférences soit en remplissant le formulaire dans l’onglet « RSVP » sur le site, en nous envoyant un e-mail (morgane.edouard.tienvrot@gmail.com ou par courrier à notre adresse : 72 Boulevard du Fier, 74000 Annecy`,
  },
  {
    title: "Allons-nous recevoir un faire-part ?",
    content: `Tout à fait, et vous pourrez l’afficher fièrement sur votre réfrigérateur ! Notre mariage à Saint-Tropez demande beaucoup d’organisation pour nos invités. C’est pourquoi nous avons souhaité créer ce site pour que vous puissiez avoir toutes les informations, vous organisez et confirmez ou non votre présence avant l’envoi des faire-parts.`,
  },
  {
    title:
      "Pourrons-nous nous changer entre la cérémonie religieuse et le cocktail ?",
    content: `Oui, si vous le souhaitez. La cérémonie religieuse devrait se terminer vers 15h30. Le cocktail débutera à 18h. Pour les personnes dormant sur place au Domaine, vous récupérerez votre chambre dès votre arrivée sur le lieu de réception, prévue vers 17h. Un fer à repasser sur place sera à votre disposition si besoin.`,
  },
];
