import React, { useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import "./image-with-image.css";

export const ImageWithDescription = ({
  image,
  title,
  description,
  link,
  footerDescription,
}) => {
  const [showDescription, setShowDescription] = useState(false);

  const toggleDescription = (value) => {
    setShowDescription(value);
  };

  return (
    <div
      className="containerStyle"
      onMouseOver={() => toggleDescription(true)}
      onMouseOut={() => toggleDescription(false)}
    >
      <img draggable={false} src={image} alt="img" className="imageStyle" />
      {showDescription && (
        <div className="descriptionStyle">
          <div className="titleStyle">{title}</div>
          <div className="descStyle">{description}</div>
          <a
            className="footerDescriptionStyle"
            target="_blank"
            rel="noopener noreferrer"
            href={link}
          >
            <LocationOnIcon style={{ paddingRight: "5px", fontSize: "18px" }} />
            {footerDescription}
          </a>
        </div>
      )}
    </div>
  );
};
