import React from "react";
import "../../App.css";
import { ParagraphText } from "../../components/paragraph-text";
import { TransportDetail } from "./transport-detail";
import train from "../../public/transport/train.png";
import avion from "../../public/transport/avion.png";
import bateau from "../../public/transport/bateau.png";

export const Transport = () => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <ParagraphText
          lines={["Le voyage vers Saint-Tropez"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(30pt, 6vw, 55pt)",
            lineHeight: "5px",
            paddingBottom: "15px",
          }}
        />
        <div
          style={{ display: "flex", flexDirection: "column", rowGap: "36px" }}
        >
          <TransportDetail
            transportImg={() => (
              <img src={train} alt={"train"} width={150} height={150} />
            )}
            title="Voyage en train"
            descriptionParagraph={[
              [
                "Les gares ferroviaires les plus proches de Saint-Tropez sont celles des Arcs-",
                "Draguignan (1h15), de Saint-Raphaël-Valescure (1h23) et de Toulon (1h15).",
              ],
              [
                "À Saint-Raphaël et Toulon, les locations de voiture sont possibles.",
                "Elles restent plus difficiles à la gare des Arcs-Draguignan.",
              ],
              [
                "Dans chacune de ces gares, des lignes de bus desservent Saint-Tropez :",
                "- Saint-Raphaël-Valescure : 876 – env. 1h45",
                "- Les Arcs-Draguignan : 872 – env. 50 min + 876 – env. 50 min",
                "- Toulon : 878 – env. 2h",
              ],
            ]}
            link={{
              title: "Horaires des bus",
              url: "https://services-zou.maregionsud.fr/fr/",
            }}
          />
          <TransportDetail
            transportImg={() => (
              <img src={avion} alt={"avion"} width={150} height={150} />
            )}
            imgSide="right"
            title="Voyage en avion"
            descriptionParagraph={[
              [
                "Vous avez le choix entre deux aéroports :",
                "celui de Toulon-Hyères (1h15) ou celui de Nice (1h45).",
              ],
              [
                "Il est aussi possible de louer des voitures directement",
                "depuis l’aéroport.",
              ],
            ]}
          />
          <TransportDetail
            transportImg={() => (
              <img src={bateau} alt={"bateau"} width={150} height={120} />
            )}
            title="Voyage en bateau"
            descriptionParagraph={[
              [
                "La petite touche locale pour ceux qui souhaiteront séjourner",
                "la veille de la cérémonie à Sainte-Maxime ou aux Issambres.",
              ],
              [
                "Le trajet dure environ 25 minutes et offre l’un des plus beaux",
                "panoramas sur Saint-Tropez.",
              ],
            ]}
            link={{
              title: "Horaires des navettes",
              url: "https://www.bateauxverts.com/horaires-tarifs/",
            }}
          />
        </div>
        <ParagraphText
          lines={["Le trajet vers le Domaine de Franco"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(25pt, 6vw, 55pt)",
            lineHeight: "5px",
          }}
        />
        <ParagraphText
          lines={[
            "Après la cérémonie religieuse qui aura lieu à l’Église de Saint-Tropez,",
            "nous rejoindrons notre lieu de réception qui se trouve à la Celle",
            "à environ 1h30 de Saint-Tropez.",
          ]}
          style={{
            lineHeight: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={[
            "Afin d’y accéder, vous pouvez aussi renseigner les coordonnées GPS suivantes:",
            "43°23'57.1\"N 6°01'10.6\"E.",
            "Pour un voyage plus confortable, nous vous recommandons de",
            "privilégier l’itinéraire passant par la route du Muy (D25)",
          ]}
          style={{
            lineHeight: "5px",
            paddingBottom: "10px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={[
            "Le trajet pourra s’effectuer uniquement en voiture.",
            "Pour ceux qui ne disposeraient pas de véhicule, n’hésitez pas",
            "à le mentionner dans le formulaire de réponse (onglet « RSVP »),",
            "nous mettrons en place un système de co-voiturage avec nos invités.",
          ]}
          style={{
            lineHeight: "5px",
            paddingBottom: "15px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <ParagraphText
          lines={["Informations pratiques"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(40pt, 6vw, 55pt)",
            lineHeight: "5px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "baseline",
            columnGap: "70px",
            rowGap: "24px",
            justifyContent: "center",
          }}
        >
          <TransportDetail
            textAlign="center"
            title="Où se garer pour la cérémonie ?"
            descriptionParagraph={[
              [
                "Le parking le plus pratique est celui du Nouveau Port",
                "(à 10 minutes de l’Église). Il se situe à l’entrée de",
                "Saint-Tropez (4€/heure) et vous pourrez ainsi plus",
                "facilement sortir du village à la fin de la cérémonie.",
              ],
            ]}
          />
          <TransportDetail
            alignItems="center"
            textAlign="center"
            title="Où se garer au Domaine de Franco ?"
            descriptionParagraph={[
              [
                "Un grand parking surveillé sera à la disposition",
                "de tous nos invités sur le lieu de réception.",
              ],
            ]}
          />
        </div>
      </span>
    </div>
  );
};
