import React from "react";
import "../../App.css";
import { ParagraphText } from "../../components/paragraph-text";
import { ParagraphWithHeader } from "../../components/paragraph-with-header";
import sun from "../../public/program/sun.png";
import church from "../../public/program/church.png";
import car from "../../public/program/car.png";
import domaine from "../../public/program/domaine.png";
import party from "../../public/program/party.png";
import morning from "../../public/program/morning.png";
import brunch from "../../public/program/brunch.png";
import day from "../../public/program/day.png";

export const Program = () => {
  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <ParagraphText
          lines={["Bienvenue"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(40pt, 6vw, 55pt)",
            lineHeight: "5px",
            height: "120px",
          }}
        />
        <ParagraphText
          lines={[
            "Nous savons que pour certains, le voyage est long pour venir jusqu’à notre",
            "mariage. Pour vous aider dans votre organisation, voici les premières",
            "informations sur le programme.",
          ]}
          style={{ fontSize: "clamp(10px, 2vw, 15px)", lineHeight: "4px" }}
        />
        <ParagraphText
          lines={["Le samedi 7 septembre 2024"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(35pt, 6vw, 55pt)",
            lineHeight: "5px",
            paddingTop: "40px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "110px",
            rowGap: "24px",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <ParagraphWithHeader
            headerImg={() => (
              <img src={sun} alt={"sun"} width={200} height={200} />
            )}
            title="Le matin – Temps libre"
            description={[
              "Profitez de la beauté du village",
              "d’Edouard, arpentez ses ruelles",
              "pavées et authentiques, pour ensuite",
              "vous prélassez à la terrasse du",
              "Sénéquier juste avant la célébration",
              "du mariage. Retrouvez un florilège de",
              "nos adresses préférées dans l’onglet",
              "« Notre Saint-Tropez ».",
            ]}
          />
          <ParagraphWithHeader
            headerImg={() => (
              <img
                src={church}
                alt={"sun"}
                width={150}
                height={150}
                style={{ paddingBottom: "30px" }}
              />
            )}
            title="14h30 – Cérémonie religieuse"
            description={[
              "Notre mariage sera célébré en l’Église",
              "Notre-Dame-de-l'Assomption",
              "(rue Commandant Guichard).",
              "Suivez le clocher, vous ne pourrez pas",
              "la louper.",
            ]}
          />
          <ParagraphWithHeader
            headerImg={() => (
              <img
                src={car}
                alt={"car"}
                width={150}
                height={150}
                style={{ paddingBottom: "30px" }}
              />
            )}
            title="16h – Départ pour le lieu de réception"
            description={[
              "Le cocktail, le dîner et la soirée auront lieu",
              "au Domaine de Franco (2800 route de la",
              "Roquebrussanne, 83170 La Celle).",
              "Il faudra prévoir entre 1h et 1h30 de trajet",
              "après la cérémonie",
              "(voir l’onglet « Transport »).",
            ]}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "110px",
            rowGap: "24px",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <ParagraphWithHeader
            headerImg={() => (
              <img src={domaine} alt={"domaine"} width={200} height={200} />
            )}
            title="17h-18h – Check-in au Domaine"
            description={[
              "Nous avons la possibilité de loger nos",
              "invités sur le lieu de la réception",
              "(voir l’onglet « Hébergement »).",
              "Ceux qui le désirent peuvent",
              "confirmer le souhait de dormir sur",
              "place via le formulaire d’inscription.",
            ]}
          />
          <ParagraphWithHeader
            headerImg={() => (
              <img
                src={party}
                alt={"party"}
                width={130}
                height={150}
                style={{ paddingBottom: "30px" }}
              />
            )}
            title="18h – Début des festivités"
            description={[
              "Petits-fours, bossa nova, paillettes",
              "et pleins d’autres surprises sont au",
              "programme d’une soirée inoubliable.",
            ]}
          />
        </div>
        <ParagraphText
          lines={["Le dimanche 8 septembre 2024"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(30pt, 6vw, 55pt)",
            lineHeight: "5px",
            paddingTop: "40px",
          }}
        />
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            columnGap: "110px",
            rowGap: "24px",
            justifyContent: "center",
            alignItems: "baseline",
          }}
        >
          <ParagraphWithHeader
            headerImg={() => (
              <img src={morning} alt={"sun"} width={150} height={150} />
            )}
            title="Le matin – Relax"
            description={[
              "Après une courte nuit,",
              "ressourcez-vous au bord de la",
              "piscine, bercés par les cigales",
              "à l’ombre des oliviers.",
            ]}
          />
          <ParagraphWithHeader
            headerImg={() => (
              <img src={brunch} alt={"sun"} width={150} height={150} />
            )}
            title="13h – Brunch"
            description={[
              "Profitez d’un brunch les pieds",
              "dans l’eau et la tête encore",
              "dans les nuages de la soirée",
              "de la veille.",
            ]}
          />
          <ParagraphWithHeader
            headerImg={() => (
              <img src={day} alt={"sun"} width={150} height={150} />
            )}
            title="14 - 17h – Amour Play day"
            description={[
              "Avant de reprendre la route, vous",
              "pourrez profiter du domaine dans son",
              "intégralité pour barboter, faire la sieste",
              "et profiter de nombreuses activités",
              "plus ou moins sportives.",
            ]}
          />
        </div>
      </span>
    </div>
  );
};
