import React, { useEffect, useState } from "react";
import Axios from "axios";
import { ParagraphText } from "../../components/paragraph-text";
import {
  Autocomplete,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
} from "@mui/material";
import { db } from "../../utils/firebase";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";

const sendEmailURL =
  " https://us-central1-wedding-ganou-doudou.cloudfunctions.net/sendEmail";

const PRESENCE_MAPPING = {
  1: "Cérémonie religieuse",
  2: "Cocktail",
  3: "Dîner",
  4: "Brunch",
  5: "Ne pourra pas participer",
};

const PRESENCE = {
  1: false,
  2: false,
  3: false,
  4: false,
  5: false,
};

const SLEEP_MAPPING = {
  1: "Oui et une préférence pour ne pas partager la chambre",
  2: "Oui et sans problème pour partager la chambre",
  3: "Non",
};

const SLEEP = {
  1: false,
  2: false,
  3: false,
};
export const Form = () => {
  const [formData, setFormData] = useState({
    guest: "",
    additionalGuest: "",
    presence: PRESENCE,
    sleep: SLEEP,
    food: "",
    music: "",
    other: "",
  });
  const [guestOptions, setGuestOptions] = useState([]);
  const [selectedGuest, setSelectedGuest] = useState(null);
  const [message, setMessage] = useState("");

  const reset = () => {
    setFormData({
      guest: "",
      additionalGuest: "",
      presence: PRESENCE,
      sleep: SLEEP,
      food: "",
      music: "",
      other: "",
    });
    setSelectedGuest(null);
  };
  useEffect(() => {
    if (guestOptions.length > 0) return;
    db.collection("guest")
      .get()
      .then((querySnapshot) => {
        const guest = [];
        querySnapshot.forEach((item) => {
          const { fullName, additionalGuest } = item.data();
          guest.push({
            label: fullName,
            value: item.id,
            additionalGuest: additionalGuest,
          });
        });
        setGuestOptions(guest.sort((a, b) => a.label.localeCompare(b.label)));
      });
  });

  const submit = (e) => {
    e.preventDefault();
    const params = {
      ...formData,
      guests: [],
      presence: [],
      sleep: "",
      subject: "Réponse Mariage...",
    };
    if (formData.guest.length > 0) params.guests.push(formData.guest);
    if (formData.additionalGuest.length > 0)
      params.guests.push(formData.additionalGuest);
    for (const [key, value] of Object.entries(formData.presence)) {
      if (value === true) {
        params.presence.push(PRESENCE_MAPPING[key]);
      }
    }
    for (const [key, value] of Object.entries(formData.sleep)) {
      if (value === true) {
        params.sleep = SLEEP_MAPPING[key];
      }
    }
    Axios.get(sendEmailURL, {
      params,
    }).then(() => {
      setMessage("🎉 Félicitation ! Votre réponse a bien été envoyée 🎉");
      reset();
    });
    return params;
  };

  return (
    <div style={{ display: "grid", justifyItems: "center" }}>
      <span
        style={{
          display: "grid",
          textAlign: "center",
          backgroundColor: "white",
          width: "85%",
          justifyItems: "center",
          paddingBottom: "50px",
        }}
      >
        <ParagraphText
          lines={["Pour nous répondre"]}
          style={{
            fontFamily: "siteFont",
            fontSize: "clamp(40pt, 6vw, 55pt)",
            lineHeight: "5px",
            height: "120px",
          }}
        />
        <ParagraphText
          lines={[
            "Merci d’avance de prendre le temps de remplir le formulaire sur cette page.",
            "Nous espérons profiter de ce week-end à vos côtés. À très bientôt !",
          ]}
          style={{
            lineHeight: "5px",
            paddingBottom: "30px",
            fontSize: "clamp(10px, 1.5vw, 15px)",
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "92%",
          }}
        >
          <Autocomplete
            freeSolo
            id="nom"
            options={guestOptions.map((option) => option)}
            value={selectedGuest ? selectedGuest.label : ""}
            onChange={(event, values) => {
              setSelectedGuest(values || null);
              setMessage("");
              setFormData({
                ...formData,
                additionalGuest: values?.additionalGuest || "",
                guest: values?.label || "",
              });
            }}
            renderInput={(params) => (
              <TextField {...params} label="Cherchez votre nom..." />
            )}
          />
          {selectedGuest && (
            <>
              <ParagraphText
                lines={[
                  selectedGuest.additionalGuest
                    ? "Invités (veuillez décocher la case en cas d'absence):"
                    : "Invité:",
                ]}
                style={{
                  paddingTop: "20px",
                  fontWeight: "bold",
                  textAlign: "left",
                  fontSize: "clamp(10px, 1.5vw, 15px)",
                }}
                required
              />
              <FormGroup style={{ paddingLeft: "20px" }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(event) => {
                        setFormData({
                          ...formData,
                          guest: event.target.checked
                            ? selectedGuest.label
                            : "",
                        });
                        setMessage("");
                      }}
                    />
                  }
                  label={
                    <span
                      style={{
                        fontFamily: "analogist",
                        fontSize: "clamp(11px, 1.5vw, 14px)",
                      }}
                    >
                      {selectedGuest.label}
                    </span>
                  }
                />
                {selectedGuest.additionalGuest && (
                  <FormControlLabel
                    control={
                      <Checkbox
                        defaultChecked
                        onChange={(event) => {
                          setFormData({
                            ...formData,
                            additionalGuest: event.target.checked
                              ? selectedGuest.additionalGuest
                              : "",
                          });
                          setMessage("");
                        }}
                      />
                    }
                    label={
                      <span
                        style={{
                          fontFamily: "analogist",
                          fontSize: "clamp(11px, 1.5vw, 14px)",
                        }}
                      >
                        {selectedGuest.additionalGuest}
                      </span>
                    }
                  />
                )}
              </FormGroup>
            </>
          )}
          <ParagraphText
            lines={["Confirme la présence pour :"]}
            style={{
              paddingTop: "20px",
              fontWeight: "bold",
              textAlign: "left",
              height: "20px",
              fontSize: "clamp(10px, 1.5vw, 15px)",
            }}
            required
          />
          <ParagraphText
            lines={[
              "Merci de cocher la/les cases où nous aurons la chance de compter sur vous.",
            ]}
            style={{
              textAlign: "left",
              fontStyle: "italic",
              fontSize: "clamp(8px, 1.5vw, 14px)",
              marginTop: "-8px",
            }}
          />
          <FormGroup required style={{ paddingLeft: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.presence[1]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      presence: {
                        ...formData.presence,
                        1: event.target.checked,
                        5: false,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Cérémonie religieuse, à 14h30 le samedi 7 septembre à
                  Saint-Tropez
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.presence[2]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      presence: {
                        ...formData.presence,
                        2: event.target.checked,
                        5: false,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Cocktail, à 18h le samedi 7 septembre au Domaine de Franco
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.presence[3]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      presence: {
                        ...formData.presence,
                        3: event.target.checked,
                        5: false,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Dîner, à 21h le samedi 7 septembre au Domaine de Franco
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.presence[4]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      presence: {
                        ...formData.presence,
                        4: event.target.checked,
                        5: false,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Brunch, à 13h le dimanche 8 septembre au Domaine de Franco
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.presence[5]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      presence: {
                        1: false,
                        2: false,
                        3: false,
                        4: false,
                        5: event.target.checked,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Ne pourra pas participer
                </span>
              }
            />
          </FormGroup>
          <ParagraphText
            lines={["Souhaitez-vous dormir sur place ?"]}
            style={{
              paddingTop: "20px",
              fontWeight: "bold",
              textAlign: "left",
              height: "25px",
              fontSize: "clamp(10px, 1.5vw, 15px)",
            }}
            required
          />
          <ParagraphText
            lines={["Merci de cocher une case suivant votre préférence"]}
            style={{
              textAlign: "left",
              fontStyle: "italic",
              fontSize: "clamp(8px, 1.5vw, 14px)",
              marginTop: "-8px",
            }}
          />
          <FormGroup required style={{ paddingLeft: "20px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.sleep[1]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      sleep: {
                        1: event.target.checked,
                        2: false,
                        3: false,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Oui avec plaisir, avec une préférence pour ne pas partager la
                  chambre
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.sleep[2]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      sleep: {
                        1: false,
                        2: event.target.checked,
                        3: false,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Oui et sans problème pour partager la chambre
                </span>
              }
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.sleep[3]}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      sleep: {
                        1: false,
                        2: false,
                        3: event.target.checked,
                      },
                    });
                    setMessage("");
                  }}
                />
              }
              label={
                <span
                  style={{
                    fontFamily: "analogist",
                    fontSize: "clamp(8px, 1.5vw, 14px)",
                  }}
                >
                  Non
                </span>
              }
            />
          </FormGroup>
          <ParagraphText
            lines={["Des allergies, régimes alimentaires ?"]}
            style={{
              paddingTop: "20px",
              fontWeight: "bold",
              textAlign: "left",
              height: "25px",
              fontSize: "clamp(10px, 1.5vw, 15px)",
            }}
          />
          <ParagraphText
            lines={[
              "Merci de préciser toutes informations utiles pour le traiteur de notre mariage",
            ]}
            style={{
              textAlign: "left",
              fontStyle: "italic",
              fontSize: "clamp(8px, 1.5vw, 14px)",
              marginTop: "-4px",
            }}
          />
          <TextField
            style={{ width: "100%" }}
            placeholder="Ecrivez ici..."
            value={formData.food}
            onChange={(event) => {
              setFormData({ ...formData, food: event.target.value });
              setMessage("");
            }}
            multiline
            rows={2}
            maxRows={4}
          />
          <ParagraphText
            lines={[
              "Quelle musique vous ferait bouger sur la piste de danse ?",
            ]}
            style={{
              paddingTop: "20px",
              fontWeight: "bold",
              textAlign: "left",
              height: "25px",
              fontSize: "clamp(10px, 1.5vw, 15px)",
            }}
          />
          <ParagraphText
            lines={["On refuse la danse des tongs et Patrick Sébastien !"]}
            style={{
              textAlign: "left",
              fontStyle: "italic",
              fontSize: "clamp(8px, 1.5vw, 14px)",
              marginTop: "-4px",
            }}
          />
          <TextField
            style={{ width: "100%" }}
            placeholder="Ecrivez ici..."
            value={formData.music}
            onChange={(event) => {
              setFormData({ ...formData, music: event.target.value });
              setMessage("");
            }}
            multiline
            rows={2}
            maxRows={4}
          />
          <ParagraphText
            lines={["Autres commentaires ou questions ?"]}
            style={{
              paddingTop: "20px",
              fontWeight: "bold",
              textAlign: "left",
              height: "40px",
              fontSize: "clamp(10px, 1.5vw, 15px)",
            }}
          />
          <TextField
            style={{ width: "100%", paddingBottom: "50px" }}
            placeholder="Ecrivez ici..."
            value={formData.other}
            onChange={(event) => {
              setFormData({ ...formData, other: event.target.value });
              setMessage("");
            }}
            multiline
            rows={2}
            maxRows={4}
          />
        </div>
        {message && (
          <ParagraphText
            lines={[message]}
            style={{
              fontWeight: "bold",
            }}
          />
        )}
        {(Object.values(formData.presence).every((p) => !p) ||
          (!formData.guest && !formData.additionalGuest) ||
          Object.values(formData.sleep).every((p) => !p)) &&
          !message && (
            <ParagraphText
              required
              lines={["Veuillez remplir les champs obligatoires du formulaire"]}
              style={{
                fontWeight: "bold",
                fontSize: "clamp(9px, 1.5vw, 15px)",
              }}
            />
          )}
        <Button
          variant="outlined"
          endIcon={<SendIcon />}
          color="inherit"
          onClick={submit}
          disabled={
            Object.values(formData.presence).every((p) => !p) ||
            (!formData.guest && !formData.additionalGuest) ||
            Object.values(formData.sleep).every((p) => !p)
          }
        >
          Envoyer ma réponse
        </Button>
      </span>
    </div>
  );
};
