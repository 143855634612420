import React from "react";
import "../App.css";
import { ParagraphText } from "./paragraph-text";

export const ParagraphWithHeader = ({ headerImg, title, description }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {headerImg()}
      <ParagraphText
        lines={[title]}
        style={{
          fontWeight: "bold",
          height: "fit-content",
          fontSize: "clamp(14px, 2vw, 17px)",
        }}
      />
      <ParagraphText
        lines={description}
        style={{
          lineHeight: "5px",
          fontSize: "clamp(12px, 1.5vw, 15px)",
        }}
      />
    </div>
  );
};
